import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {WOW} from 'wowjs';
import { Link } from 'react-scroll';
import { useSearchParams } from "react-router-dom";
import Scroll from 'react-scroll';
import { IOS_STORE_LINK } from 'config/constants';

const scroll = Scroll.scroller;

function Footer(props) {
 	const [searchParams] = useSearchParams();

	useEffect(() => {

		const wow = new WOW({
        //animateClass: 'animated',
        //offset: 100,
        mobile: false,
        live: false
    })

    wow.init();
	}, [WOW]);


  useEffect(() => {
    const scrollTo = searchParams.get('scrollTo');

    if (scrollTo) {
      scroll.scrollTo(scrollTo, {
        duration: 200,
			  delay: 100,
			  smooth: true,
      });
    }
  }, [searchParams]);
	

		return (
			<footer>
				
				{/* <!-- Widgets --> */}
				<div className="footer-widgets">
					<Container>
						
						<Row>
							
							{/* <!-- Footer logo --> */}
							<Col className="col-12 col-md-6 col-lg-3 res-margin">
								<div className="widget">
									<p className="footer-logo">
										<img src="/images/logo-circle-alt.png" alt="Talons" data-rjs="3" />
									</p>
									<p>
										Join us on social media
									</p>
									
									{/* <!-- Social links --> */}
									<div className="footer-social">
										<a href="https://twitter.com/talons__io" title="Twitter" target="_blank"><i className="fab fa-twitter fa-fw"></i></a>
										<a href="#" title="Facebook" target="_blank"><i className="fab fa-facebook-f fa-fw"></i></a>
										<a href="https://www.instagram.com/talons.io" title="Instagram" target="_blank"><i className="fab fa-instagram"></i></a>
									</div>
								</div>
							</Col>
							
							{/* <!-- Useful links --> */}
							<Col className="col-12 col-md-6 col-lg-2 offset-lg-1 res-margin">
								<div className="widget">
									
									<h6>Company</h6>
									
									<ul className="footer-menu">
										<li><a href="/privacy-policy">Privacy Policy</a></li>
										<li><a href="/terms-and-conditions">Terms and Conditions</a></li>
									</ul>									
								</div>
							</Col>
							
							
						</Row>
						
					</Container>
				</div>
				
				{/* <!-- Copyright --> */}
				<div className="footer-copyright">				
					<Container>
						
						<Row>						
							<Col className="col-12">
								
								{/* <!-- Text --> */}
								<p className="copyright text-center">
									Copyright © 2023 Talons Sports LLC. All Rights Reserved.
								</p>
								
							</Col>
						</Row>
						
					</Container>				
				</div>
				
			</footer>
		);
	

}


export default Footer;
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { API_KEY, API_BASE_URL } from 'config/constants';
import { setStorage, getStorage, removeStorageItem } from 'utils/storage';

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const fetchAPI = (url, {parameters=null, method='get', contentType='application/json', signal=null}, token=null) => {  
  const headers = {
    "Accept": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "e_platform": "web",
    "Content-Type": contentType,
    'x-api-key': API_KEY,
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const instance = axios.create({
    headers,
  });

  const options = {
    method,
    url,
  }

  if (parameters) {
    options.data = parameters;
  }

  // an alternative way to cancel Axios requests using AbortController
  // signal: new AbortController().signal
  if (signal) {
    options.signal = signal;
  }


  console.log(">>>>> options:", options)

  instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // return response?.data?.data || response?.data || response;

    try {
      return response.data.data
    } catch (err) {
      try {
        return response.data;
      } catch (err) {
        return response;
      }
    }
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response ? error.response.data || error.response : error);
  });

  return instance.request(options)
}

const validateUserTokens = ({getStoreActions}) => {
 return new Promise((resolve, reject) => {
    console.log(">>>> validateUserTokens: 000")
    const {validateNetTokens} = getStoreActions().auth;

    validateNetTokens().then(result => resolve(result)).catch(err => {});
  });
}

const memberFetchAPI = (url, obj, {getStoreActions}) => {
  return new Promise((resolve, reject) => {
    validateUserTokens({getStoreActions}).then(token_resp => {
      const {idToken, accessToken, refreshToken} = token_resp;

      const execFetch = () => {
        return new Promise((resolve, reject) => {
          Promise.race([
            fetchAPI(url, obj, idToken),
            new Promise((_, reject) => { setTimeout(() => {
              reject(new Error('Timeout'));
            }, 10000)}),
          ]).then((resp) => {
            if (obj.showProgress) {
              // showLoader(false);
            }

            setTimeout(() => {
              resolve(resp);
            }, 200); // adding delay or else Alert.alert wont show
          }).catch(err => {
            reject(err);
          });
        }) 
      }

      execFetch()
      .then(result => resolve(result))
      .catch((err) => {
        if (err.message === 'Timeout' || err.message === 'Network request failed') {
          // retry
          execFetch()
          .then(result => resolve(result))
          .catch((err) => {
            setTimeout(() => {
              reject(err);
            }, 200);
          })
        } else {
          // rethrow other unexpected errors
          setTimeout(() => {
            reject(err);
          }, 200);
        }
      })
    }).catch((err) => {
      if (obj.showProgress) {
        // showLoader(false);
      }

      setTimeout(() => {
        reject(err);
      }, 200);
    });
  });
}

export {
  fetchAPI,
  memberFetchAPI,
}

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Container, Row, Col } from 'react-bootstrap';
import { NewsletterForm } from 'screens/app/forms/NewsletterForm';
import { useApp } from 'providers/app';
import RecaptchaForm from 'components/forms/RecaptchaForm';
import RecaptchaText from 'components/misc/RecaptchaText';

const SubscribeForm = () => {
  const { addToast } = useApp();

  const { subscribeToNewsletter } = useStoreActions(actions => actions.app);

  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <div id="subscribe-form">
      <RecaptchaForm form="newsletter">
        {({recaptcha, getRecaptcha}) => {

          return (
            <NewsletterForm
              disabled={disabled}
              onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
                const {email} = values;

                subscribeToNewsletter({
                  email,
                  recaptcha,
                }).then(res => {
                  setSubmitting(false);
                  setDisabled(true);
                  resetForm();
                  setStatus('success');
                  addToast({
                    type: 'success',
                    header: 'Success',
                    body: 'Thank you for subscribing'
                  });
                }).catch(err => {
                  getRecaptcha();
                  setSubmitting(false);
                  setStatus('error');
                  addToast({
                    type: 'danger',
                    header: 'Opps...',
                    body: 'There was a problem trying to subscribe'
                  });
                });
              }}
            />
          )
        }}
      </RecaptchaForm>
      <RecaptchaText className="mt-4" />
    </div>
  );
};

const Subscribe = () => {
  const parallax = useRef(null);

  useEffect(() => {
    if (parallax.current) {
      parallax.current.style.backgroundImage = `url(${parallax.current.getAttribute('data-image')})`;
    }
  }, [parallax]);
  

  return (
    <section id="subscribe" className="parallax" data-image="/images/parallax/subscribe.jpg" ref={parallax}>
			
			{/* <!-- Overlay --> */}
			<div className="overlay"></div>	
			
			{/* <!-- Container --> */}
			<Container>

        {/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center white pb-4">
							<h3 className="text-white">Subscribe To Newsletter</h3>
							<p>Sign up for the latest news and updates. Be the first to know about our tips, specials, and promotions.</p>
            </div>
						
					</Col>
				</Row>

        {/* <!-- Newsletter form --> */}
        <Row className="justify-content-center">
          <Col className="col-12 col-md-10 col-lg-6">
            
            <SubscribeForm />
            
            <p className="mt-2 mb-0 recaptcha-terms">             
              We don't share your personal information with anyone or company. 
              Check out our <a href="/privacy-policy">Privacy Policy</a> for more information.
            </p>
            
          </Col>
        </Row>

      </Container>
				
		</section>
  );
}

export default Subscribe;
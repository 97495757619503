import { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PK } from 'config/constants';

const stripePromise = loadStripe(STRIPE_PK);

const StripeContext = createContext(null);

function StripeProvider(props) {

  return ( 
    <div className="AppWrapper">   
      <Elements stripe={stripePromise}>
        <StripeContext.Provider    
          value={{}}      
        >
          {props.children}
        </StripeContext.Provider>
      </Elements>
    </div>  
  )
}

const useStripe = () => {
  return useContext(StripeContext);
}

export {
  StripeProvider,
  useStripe
}
import _ from 'lodash';
import jwt_decode from 'jwt-decode';

const filterAuthErrorMessages = (error) => {
  let msg = error;

  const list = [
    'PreSignUp',
    'PostConfirmation',
    'PreAuthentication',
    'PostAuthentication',
    'UserMigration',
    'CustomMessage',
    'PreTokenGeneration'
  ]

  _.each(list, (e) => {
    msg = msg.replace(`${e} failed with error `, '');
  })

  return msg;
}

const getUserAttributes = ({idToken, accessToken, refreshToken}) => {
  const attributes = jwt_decode(idToken);

  const userData = _.pick(attributes, [
    'phone_number',
    'ble_characteristic_uuid',
    'name',
    'refresh_token_expires',
    'customer_id',
    'email',
    'subscribe_plaftorm',
  ])

  const user_roles = attributes['user_roles'].split(',');

  const user = Object.assign(userData, {
    user_uuid: attributes['custom:user_uuid'],
    user_roles,
    email_verified: attributes['email_confirmed'] === 'true',
    mobile_verified: attributes['mobile_verified'] === 'true',
  });

  return user; 
}

export {
  getUserAttributes,
  filterAuthErrorMessages,
}

function ErrorNotFound() {

  return (
    <div>
      <h3>
        No match 
      </h3>
    </div>
  );
}

export default ErrorNotFound;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { IOS_STORE_LINK } from 'config/constants';


const VideoBackground = () => {
    return (
        <section id="home" className="banner video-bg bottom-oval" style={{height: 1000}}>

			{/* <VideoBG /> */}

			{/* <!-- Container --> */}
			<Container>

				<Row className="align-items-center">

					{/* <!-- Content --> */}
					<Col className="col-12 col-lg-6 offset-lg-3">

						{/* <!-- Banner text --> */}
						<div className="banner-text text-center">

							<h1 className="wow fadeInUp mb-5" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0s">
								Talons Studio
							</h1>

							<div className="button-store wow fadeInUp mt-5" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">
								
								<a href="#" className="d-inline-flex align-items-center m-2 m-sm-0 me-sm-3" target="_blank">
									<img src="/images/download-google-play.png" alt="" />
								</a>
								
								<a href={IOS_STORE_LINK} className="d-inline-flex align-items-center m-2 m-sm-0" target="_blank">
									<img src="/images/download-app-store.png" alt="" />
								</a>
							
							</div>

						</div>
						
						<div className="empty-30"></div>
						
					</Col>  
					
				</Row>
				
			</Container>
			
			{/* <!-- Image --> */}
			<div className="banner-image-center w-100 wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
				<img src="/images/screenshots/tablet-full.png" alt="" style={{width: 800}} />
			</div>
			
		</section>
    );
}

export default VideoBackground;
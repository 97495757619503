import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink, useLocation } from "react-router-dom";
import { Link } from 'react-scroll';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CgProfile } from 'react-icons/cg';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from 'providers/auth';

const Header = (props) => {
  let location = useLocation();

  const { logout } = useStoreActions(actions => actions.auth);
  const { user } = useAuth();
  const { user_uuid, name } = user;

  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [hash, setHash] = useState('');

  const handleMobileMenu = e => {
    e.preventDefault();
    return setIsMobileMenu(!isMobileMenu);
  };

  const handleSearch = e => {
    e.preventDefault();
    return setIsSearch(!isSearch);
  };

  return (
    <>
      <header id="top-page" className={'header header-static'}>
        <div id="mainNav" className={isMobileMenu ? 'main-menu-area animated mobile-menu-open' : 'main-menu-area animated'}>
          <Container>
            <Row className="align-items-center">
              
              <Col className="col-12 col-lg-2 d-flex justify-content-between align-items-center">

                {/* <!-- Logo --> */}
                <div className="logo">
                  
                  <NavLink to="/" className="navbar-brand navbar-brand1">
                    <img src="/images/logo-90.png" srcSet={`/images/logo-90@2x.png 2x`} alt="Talons" />
                  </NavLink> 

                  <NavLink to="/" className="navbar-brand navbar-brand2">
                    <img src="/images/logo-dark-90.png" srcSet={`/images/logo-dark-90@2x.png 2x`} alt="Talons" />
                  </NavLink>
                
                </div>

                {/* <!-- Burger menu --> */}
                <div className="menu-bar d-lg-none" role="button" tabIndex={0} onKeyPress={() => {}} onClick={handleMobileMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

              </Col>

              <div className="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end" role="button" tabIndex={0} onKeyPress={() => {}} onClick={handleMobileMenu}>
              
                {/* <!-- Mobile menu --> */}
                <div className="m-menu-header d-flex justify-content-between align-items-center d-lg-none">
                  
                  {/* <!-- Logo --> */}
                  <a href="#" className="logo">
                     <img src="/images/logo-90.png" srcSet={`/images/logo-90@2x.png 2x`} alt="Talons" />
                  </a>
                  
                  {/* <!-- Close button --> */}
                  <span className="close-button" role="button" tabIndex={0} onKeyPress={() => {}} onClick={handleMobileMenu}></span>
                  
                </div>
                
                {/* <!-- Items --> */}
                <ul className="nav-menu d-lg-flex flex-wrap list-unstyled justify-content-center">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link js-scroll-trigger">
                      <span>Talons Studio</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/privacy-policy" className="nav-link js-scroll-trigger">
                      <span>Privacy Policy</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/terms-and-conditions" className="nav-link js-scroll-trigger">
                      <span>Terms and Conditions</span>
                    </NavLink>
                  </li>
                  

                  {/*
                  <li className="nav-item search-option">
                    <a className="nav-link" href="#" onClick={handleSearch}>
                      <i className="fas fa-search"></i>
                    </a>
                  </li>
                  */}

                </ul>
                
              </div>

            </Row>
          </Container>
        </div>
      </header>

      {/* <!-- Search wrapper --> */}
      {/*
      <div className={!isSearch ? 'search-wrapper' : 'search-wrapper wrapper-active'}>
        <form role="search" method="get" className="search-form" action="#">        
          <input type="search" name="s" id="s"
            placeholder="Search Keyword"
            className="searchbox-input" autoComplete="off" required />
          
          <span>Input your search keywords and press Enter.</span>      
        </form>

        <div className="search-wrapper-close">
          <span className="search-close-btn" role="button" tabIndex={0} onKeyPress={() => {}} onClick={handleSearch}></span>
        </div>

      </div>
      */}
    </>
  );
}

export default Header;
import React, { useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Element } from 'react-scroll';
import { MdOutlineSlowMotionVideo, MdPhotoCameraFront, MdWifiTethering, MdOutlineDevicesOther } from 'react-icons/md';
import { GiMirrorMirror } from 'react-icons/gi';
import { RiLightbulbFlashLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import { InView } from 'react-intersection-observer';

const Features = (props) => {

	useEffect(() => {

	}, []);

    return (
        <Element id="features" name="features" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>A visual feedback system for your game</h3>
							{/*
							<p>Sed laoreet diam sagittis tempus convallis. Interdum et malesuada fames ac ante ipsum primis in faucibus. </p>
							*/}
						</div>
						
					</Col>
				</Row>
			
				
				
			</Container>
			
		</Element>
    );
}

export default Features;
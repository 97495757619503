import Storage from 'localforage';
import { COGNITO_CLIENT_ID } from 'config/constants';
import jwt_decode from 'jwt-decode';

const AUTHSTORAGE_KEY_PREFIX = '@AuthStorage:';
let dataMemory = {};

/** @class */
class AuthStorage {
  static syncPromise = null;
  /**w
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    Storage.setItem(AUTHSTORAGE_KEY_PREFIX + key, value);
    dataMemory[key] = value;
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    Storage.removeItem(AUTHSTORAGE_KEY_PREFIX + key);
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Will sync the AuthStorage data from AsyncStorage to storageWindow AuthStorage
   */
  static sync() {
    if (!AuthStorage.syncPromise) {
      AuthStorage.syncPromise = new Promise((res, rej) => {

        /*

        AsyncStorage.getAllKeys((errKeys, keys) => {
          if (errKeys) rej(errKeys);
          const memoryKeys = keys.filter((key) =>
            key.startsWith(AUTHSTORAGE_KEY_PREFIX)
          );
          AsyncStorage.multiGet(memoryKeys, (err, stores) => {
            if (err) rej(err);
            stores.map((result, index, store) => {
              const key = store[index][0];
              const value = store[index][1];
              const memoryKey = key.replace(AUTHSTORAGE_KEY_PREFIX, '');
              dataMemory[memoryKey] = value;
            });
            res();
          });
        });
        */

        /*
        Storage.keys().then(function(keys) {
            // An array of all the key names.
            console.log(keys);
        }).catch(function(err) {
          rej(err);
        });
        */

        Storage.iterate(function(value, key, iterationNumber) {
            // Resulting key/value pair -- this callback
            // will be executed for every item in the
            // database.
          /*
            const list = [];
            // console.log([key, value]);
            if (key.startsWith(AUTHSTORAGE_KEY_PREFIX)) {
              list;push({key, value})
            }

            return list;
            */
            if (key.startsWith(AUTHSTORAGE_KEY_PREFIX)) {
              const memoryKey = key.replace(AUTHSTORAGE_KEY_PREFIX, '');
              dataMemory[memoryKey] = value;
            }
            return;
        }).then(function() {
            console.log('Iteration has completed');
            res();
        }).catch(function(err) {
            // This code runs if there were any errors
            console.log(err);
            rej(err);
        });
      });
    }
    return AuthStorage.syncPromise;
  }
}

const calculateClockDrift = (iatAccessToken, iatIdToken) => {
  const now = Math.floor(new Date() / 1000);
  const iat = Math.min(iatAccessToken, iatIdToken);
  return now - iat;
};

const setAuthData = ({ idToken, accessToken, refreshToken }) => {
  try {
    const storage = AuthStorage;

    const idTokenRes = jwt_decode(idToken);
    const accessTokenRes = jwt_decode(accessToken);

    const iatAccessToken = accessTokenRes.iat;
    const iatIdToken = idTokenRes.iat;
    const username = idTokenRes['cognito:username'];
    const ClientId = accessTokenRes.client_id;

    const prefix = `CognitoIdentityServiceProvider.${ClientId}`

    storage.setItem(`${prefix}.LastAuthUser`, username);
    storage.setItem(`${prefix}.${username}.idToken`, idToken);
    storage.setItem(`${prefix}.${username}.accessToken`, accessToken);
    storage.setItem(`${prefix}.${username}.refreshToken`, refreshToken);
    storage.setItem(`${prefix}.${username}.clockDrift`, `${calculateClockDrift(iatAccessToken, iatIdToken)}`);
  }
  catch (err) {
     // console.log(">>>> setAuthData err:", err)
  }
}

export { AuthStorage, setAuthData };

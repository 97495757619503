import { thunk, persist } from 'easy-peasy';
import app from './app';
import auth from './auth';
import subscriptions from './subscriptions';

export default {
  app: persist(app, {
    whitelist: [
      
    ],
  }),
  auth: persist(auth, {
    whitelist: [
      'idToken',
      'accessToken',
      'refreshToken',
    ],
  }),
  subscriptions: persist(subscriptions, {
    whitelist: [
      'subscriptions',
      'is_fetching_subscriptions'
    ],
  }),
}
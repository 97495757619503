import { useState, useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function RecaptchaForm({form, children}) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [recaptcha, setRecaptcha] = useState(null);

  const getRecaptcha = useCallback(() => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    console.log('Got recaptcha');

    executeRecaptcha(form).then(token => {
      setRecaptcha(token)
    }).catch(err => {
     setRecaptcha(null);
    });
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
   getRecaptcha();
  }, [getRecaptcha]);

  return (
    <>{children({recaptcha, getRecaptcha})}</>
  )
}

export default RecaptchaForm;
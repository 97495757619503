import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import ProtectedRoute from './ProtectedRoute';

import ErrorNotFound from 'screens/app/ErrorNotFound';


import Layout from 'components/Sections/Layout';


import Home from 'naxos/pages/home';
import Blog from 'naxos/pages/blog';
import PrivacyPolicy from 'screens/app/PrivacyPolicy';
import TermsAndConditions from 'screens/app/TermsAndConditions';
import Contact from 'screens/app/Contact';

function Navigation() {

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Home />}
      />

      <Route
        exact
        path="/contact"
        element={<Contact />}
      />

      <Route element={<Layout />}>

        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        />

        <Route
          exact
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
      </Route>
      <Route
        path="*"
        element={
          <ErrorNotFound />
        }
      />
    </Routes>
  );
}


export default Navigation;
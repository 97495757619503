import { BrowserRouter } from "react-router-dom";
import { StoreProvider, createStore} from 'easy-peasy';
import Navigation from 'navigation/index';
import model from 'model';
import { AppProvider } from 'providers/app';
import { AuthProvider } from 'providers/auth';
import { StripeProvider } from 'providers/stripe';
import { Amplify } from 'aws-amplify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SimpleReactLightbox from 'simple-react-lightbox';
import { IconContext } from "react-icons";
import { AuthStorage } from 'utils/AuthStorage';


import {
  /*
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID,
  PINPOINT_APP_ID,
  PINPOINT_REGION,
  IOT_REGION,
  IOT_ENDPOINT_HOST,
  */
  RECAPTCHA_KEY,
} from 'config/constants';

/*
Amplify.configure({  
  Auth: {
    storage: AuthStorage,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID,
    mandatorySignIn: false,
    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers        
    clientMetadata: {
      
    }
  },
  Analytics: {
    AWSPinpoint: {
      appId: PINPOINT_APP_ID,
      region: PINPOINT_REGION,
      endpoint: {
        channelType: 'APNS_SANDBOX', // Platform.OS === 'ios' ? 'APNS' : 'GCM',
      }
    }
  },
});
*/

const store = createStore(model);

function App() {
  return (
    <StoreProvider store={store}>
      <StripeProvider>
        <BrowserRouter>
          <SimpleReactLightbox>
            <IconContext.Provider value={{ style: { verticalAlign: 'middle' }, size: '2em' }}>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                <AppProvider>
                  <AuthProvider>
                    <Navigation />
                  </AuthProvider>
                </AppProvider>
              </GoogleReCaptchaProvider>
            </IconContext.Provider>
          </SimpleReactLightbox>
        </BrowserRouter>
      </StripeProvider>
    </StoreProvider>
  );
}

export default App;


{/*
<Route path='/l/:live_uuid' component={() => { 
   window.location.href = 'https://apps.apple.com/us/app/talons/id1502148925'; 
   return null;
}}/>
<Route path='/l' component={() => { 
   window.location.href = 'https://apps.apple.com/us/app/talons/id1502148925'; 
   return null;
}}/>
*/}
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form } from "react-bootstrap";
import TextField from "components/forms/TextField";

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email)
};

const schema = yup.object({
  email: yup.string()
    .required('Email is required')
    .test('email', 'Email is invalid', (value) => {
       return validateEmail(value);
    }),
});

const initialNewsletterFormValues = {
  email: "",
}

function NewsletterForm({onSubmit=null, disabled=false}) {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialNewsletterFormValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        status,
        values,
        errors,
        isValid,
        isSubmitting,
      }) => {
        return (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="form-floating mb-3">
              <TextField
                label="Enter Your Email Address"
                type="text"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>

            <Button
              disabled={!isValid || isSubmitting || disabled}
              type="submit"
              id="signup-btn"
              variant="talons-purple"
              size="lg"
              className="w-100"
             >
              {isSubmitting && (
                <>
                  <span className="spinner-border spinner-border-sm" style={{marginRight: 8}}></span> Loading...
                </>
              )}
              {!isSubmitting && (
                <>Subscribe</>
              )}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export {
  initialNewsletterFormValues,
  NewsletterForm
};
import { createContext, useEffect, useState, useContext } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { setStorage, getStorage, removeStorageItem } from 'utils/storage';
import { getUserAttributes } from 'helpers/auth';
import _ from 'lodash';

const AuthContext = createContext(null);

function AuthProvider(props) {
  const {idToken, accessToken, refreshToken} = useStoreState(state => state.auth);
  const {checkAuthStorage, refreshUserToken, validateNetTokens} = useStoreActions(actions => actions.auth);

  const [authChecked, setAuthChecked] = useState(false);
  const [isValidated, setIsValdiated] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isStandardAccount, setIsStandardAccount] = useState(false);
  const [isProAccount, setIsProAccount] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [user, setUser] = useState(null);
  const [isAccountUpgraded, setIsAccountUpgraded] = useState(false);

  useEffect(() => {
    if (authChecked) {

      if (idToken && accessToken && refreshToken) {
        setUserData({idToken, accessToken, refreshToken});
      }
      else {
        setUser(null);
        setIsAuthenticated(false);
      }
    }
  }, [idToken, accessToken, refreshToken, authChecked]);

  useEffect(() => {
    if (!authChecked) {
      checkAuthStorage().then(data => {
        if (data) {
          const {idToken, accessToken, refreshToken} = data;

          setUserData({idToken, accessToken, refreshToken});
          setAuthChecked(true);
        }
        else {
          setAuthChecked(true);
        }
      });
    }
  }, [authChecked]);

  useEffect(() => {
    if (authChecked && !isValidated && isAuthenticated) {
      validateNetTokens().then(r => {}).catch(err => {})
    }
  }, [authChecked, isValidated, isAuthenticated]);

  const setUserData = ({idToken, accessToken, refreshToken}) => {
    if (idToken && accessToken && refreshToken) {
      try {
        const user = getUserAttributes({idToken, accessToken, refreshToken});

        const is_standard_account = _.includes(user.user_roles, 'standard');
        const is_pro_account = _.includes(user.user_roles, 'pro');

        setIsStandardAccount(is_standard_account);
        setIsProAccount(is_pro_account);
        setIsMember(is_standard_account || is_pro_account);

        setUser(user);

        setIsAuthenticated(true);
      }
      catch (err) {

      }
    }
    else {
      setIsAuthenticated(false);
    }
  }

  const upgradeUser = (result) => {
    const {
      new_subscriber,
      upgrade,
      downgrade,
      user_roles,
    } = result;

    setIsAccountUpgraded(true);

    const is_standard_account = _.includes(user_roles, 'standard');
    const is_pro_account = _.includes(user_roles, 'pro');

    setIsStandardAccount(is_standard_account);
    setIsProAccount(is_pro_account);
    setIsMember(is_standard_account || is_pro_account);

    refreshUserToken().then(result => {

    }).catch(err => {

    }).finally(_ => {

    });
  }

  if (!authChecked) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        authChecked,
        user: user || {},
        isAuthenticated,
        isStandardAccount,
        isProAccount,
        isMember,
        upgradeUser,
        isAccountUpgraded,
        setIsAccountUpgraded,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  return useContext(AuthContext);
}

export {
  AuthProvider,
  useAuth,
}
import { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { Amplify } from 'aws-amplify';
import { MdErrorOutline, MdOutlineWarningAmber, MdOutlineCheckCircle } from 'react-icons/md';
import { ImInfo } from 'react-icons/im';

const AppContext = createContext(null);

function AppProvider(props) {
  const { subscriptions, is_fetching_subscriptions } = useStoreState(state => state.subscriptions);
  const { getSubscriptions } = useStoreActions(actions => actions.subscriptions);

  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    //if (!is_fetching_subscriptions && !(subscriptions?.length > 0)) {
    if (!(subscriptions?.length > 0)) {
      console.log(">>> AppProvider->subscriptions:", subscriptions);
      console.log(">>> no subscriptions found... fetch")   
      getSubscriptions({ signal: controller.signal }).then(result => {
        console.log(">>> subscriptions:", JSON.stringify(result))
      }).catch(err => {

      });
    }

    return () => {
      controller.abort();
    }
  }, [subscriptions, is_fetching_subscriptions])

  //const { storage_checked, } = useStoreState(state => state.app);  
  // const { getRegionServices } = useStoreActions(actions => actions.app);
  // const { getAuthenticatedUser } = useStoreActions(actions => actions.auth);

{/*
  useEffect(() => {
    if (!initialized) {      
      getRegionServices().then((result) => {
        console.log(">>>>> FINAL getRegionServices:", result);

        const { 
          service_region, minimum_app_version, images_cdn, recordings_cdn,
          cognito_identity_pool, cognito_region, cognito_user_pool_id, cognito_client_id,
          iot_endpoint_host, iot_region, pinpoint_app_id, pinpoint_region, is_refresh, update_date, 
        } = result;

        // if (is_refresh) {
          Amplify.configure({
            Auth: {
              authenticationFlowType: 'USER_PASSWORD_AUTH',
              identityPoolId: cognito_identity_pool,
              region: cognito_region,
              userPoolId: cognito_user_pool_id,
              userPoolWebClientId: cognito_client_id,
              mandatorySignIn: false,
              clientMetadata: {
                
              }
            },
          });

          setInitialized(true);
        // }

      }).catch(err => {
        console.log('>>>> ERROR:', err)
      });
    }
  }, [initialized]);
*/}

  /*
  useEffect(() => {
    if (!initialized && storage_checked) {
      getRegionServices().then((result) => {
        console.log(">>>>> getRegionServices:", result);

        const { 
          service_region, minimum_app_version, images_cdn, recordings_cdn,
          cognito_identity_pool, cognito_region, cognito_user_pool_id, cognito_client_id,
          iot_endpoint_host, iot_region, pinpoint_app_id, pinpoint_region, is_refresh, update_date, 
        } = result;
        const app_version = getVersion();
    
        setServerReachable(true);
        setHasMinimumAppVersion(compareVersions.compare(app_version, minimum_app_version, '>='));

        if (is_refresh) {
          if (AMPLIFY_LOG_LEVEL) {
            // Amplify.Logger.LOG_LEVEL = AMPLIFY_LOG_LEVEL;
          }

          Amplify.configure({
            Auth: {
              authenticationFlowType: 'USER_PASSWORD_AUTH',
              identityPoolId: cognito_identity_pool,
              region: cognito_region,
              userPoolId: cognito_user_pool_id,
              userPoolWebClientId: cognito_client_id,
              mandatorySignIn: false,
              // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers        
              clientMetadata: {
                device_unique_id: getUniqueId(),
              }
            },
            PushNotification: {
              appId: pinpoint_app_id,
              requestIOSPermissions: Platform.OS === 'ios',
            },
            Analytics: {
              AWSPinpoint: {
                appId: pinpoint_app_id,
                region: pinpoint_region,
                endpoint: {
                  channelType: 'APNS_SANDBOX', // Platform.OS === 'ios' ? 'APNS' : 'GCM',
                }
              }
            },
          });
          
          const provider = new AWSIoTProvider({
             aws_pubsub_region: iot_region,
             aws_pubsub_endpoint: iot_endpoint_host,
          });

          Amplify.addPluggable(provider);          

          Auth.currentCredentials().then((info) => {
            console.log(">>>> currentCredentials:", info)

            const cognito_identity_id = info.identityId;
            // console.log(">>>> cognito_identity_id:", cognito_identity_id);
            
            setInitialized();
            SplashScreen.hide();
          }).catch(err => {
            console.log(">>>> Identity err:", err)
          });

          // get the notification data when notification is received
          PushNotification.onNotification((notification) => {
            // Note that the notification object structure is different from Android and IOS
            // console.log('>>>> in app notification', notification);

            // required on iOS only (see fetchCompletionHandler docs: https://facebook.github.io/react-native/docs/pushnotificationios.html)
            if (Platform.OS === 'ios') {
              notification.finish(PushNotificationIOS.FetchResult.NoData);
            }
          });

          // get the registration token
          // This will only be triggered when the token is generated or updated.
          PushNotification.onRegister((apn_token) => {
            // console.log("######################################")
            // console.log('>>>> PushNotification->registration:', apn_token);

            updateApnToken({apn_token});
          });

          // get the notification data when notification is opened
          PushNotification.onNotificationOpened((notification) => {
            // console.log('>>>> the notification is opened', notification);
          });


          if (Platform.OS === 'ios') {
            PushNotification.requestIOSPermissions()
          } 

          const options = {};

          if (Platform.OS === 'ios') {
            options.ios = {
              appName: APP_NAME,
            };
          }
          else {
            options.android = {
              selfManaged: true,
              alertTitle: 'Permissions required',
              alertDescription: 'Talons needs to access your phone calling accounts to make live session calls',
              cancelButton: 'Cancel',
              okButton: 'ok',
              imageName: 'phone_account_icon',
              additionalPermissions: [PermissionsAndroid.PERMISSIONS.READ_CONTACTS],
              foregroundService: {
                channelId: 'io.talons.live',
                channelName: 'Talons Live',
                notificationTitle: 'Talons is running on background',
                notificationIcon: '../images/logo-90.png',
              }, 
            }
          }

          try {
            RNCallKeep.setup(options).then(accepted => {});

            if (Platform.OS === 'android') {
               RNCallKeep.setAvailable(true);
            }
          }
          catch (err) {
            console.error('>>> initializeCallKeep error:', err.message);
          }
        }
      }).catch(err => {
        console.log(">>>> catch err 999:", err)
        setServerReachable(false);
      });
    }
  }, [initialized, storage_checked]);
  */

  const addToast = useCallback(({
      dismissible=true,
      autohide=true,
      delay=3000,
      type='info',
      header=null,
      body,
    }) => {

    const list = [].concat(toasts);

    list.push({
      uuid: uuid(),
      type,
      delay,
      dismissible,
      autohide,
      header,
      body,
    });

    setToasts(list);
  }, [toasts]);

  const removeToast = useCallback(uuid => {
    const list = [].concat(toasts);

    _.remove(list, (x) => {
      return x.uuid === uuid;
    });

    setToasts(list);
  }, [toasts]);

  return (
    <AppContext.Provider    
      value={{
        addToast,
        removeToast,
      }}
    >
      {props.children}

      <ToastContainer position="top-end" className="p-3" containerPosition="fixed">
        {toasts && toasts.map((item, i) => {
          const {uuid, header, type, body, delay, dismissible, autohide} = item;

          return (
            <Toast
              key={uuid}
              autohide={autohide}
              onClose={() => {
                removeToast(uuid);
              }}
            >
              {header && (
                <Toast.Header className={type} closeVariant="white">
                  {type === 'success' && (
                    <MdOutlineCheckCircle className={type} />
                  )}
                  {type === 'warning' && (
                    <MdOutlineWarningAmber className={type} />
                  )}
                  {type === 'danger' && (
                    <MdOutlineCheckCircle className={type} />
                  )}
                  {type === 'info' && (
                    <ImInfo className={type} />
                  )}
                  <span className={type}>{header}</span>
                </Toast.Header>
              )}
              {body && (
                <Toast.Body>{body}</Toast.Body>
              )}
            </Toast>
          )
        })}
      </ToastContainer>
    </AppContext.Provider>
  );
}

const useApp = () => {
  return useContext(AppContext);
}

export {
  AppProvider,
  useApp,
}
import React from 'react';

const Loader = () => {
    return (
        <div className="page-loader">
            <div className="progress" />
        </div>
    );
}

export default Loader;
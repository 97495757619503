import { Form } from "react-bootstrap";
import { Field } from "formik";

const TextField = ({
  id=null,
  placeholder,
  label,
  name,
  type,
  inputGroupPrepend=null,  
  ...inputProps
}) => {
  return (
    <Field
      name={name}
      autoComplete={`new-${name}`}
    >
    {({ field, form }) => {
      const isValid = !form.errors[field.name];
      const isInvalid = form.touched[field.name] && !isValid;
      
      return (
        <>
          <Form.Control
            {...field}
            {...inputProps}
            id={id || field.name}
            type={type}
            isValid={form.touched[field.name] && isValid}
            isInvalid={isInvalid}
            feedback={form.errors[field.name]}
            placeholder={placeholder || label}
          />
          {label && (<label className="placeholder-label" htmlFor={id || field.name}>{label}</label>)}
          <Form.Control.Feedback type="invalid">
            {form.errors[field.name]}
          </Form.Control.Feedback>
        </>
      );
      
    }}
    </Field>
  );
};

TextField.defaultProps = {
  type: "text",
};

export default TextField;

import { action, actionOn, thunk } from 'easy-peasy';
import { setStorage, getStorage, clearAllStorage } from 'utils/storage';
import { fetchAPI, memberFetchAPI } from 'utils/net';
import _ from 'lodash';
import moment from 'moment';
import { validateJwtResponse } from 'utils/security';

export default {
 
  subscribeToNewsletter: thunk(async (actions, payload, { dispatch, getState, getStoreActions, injections }) => {
    return new Promise((resolve, reject) => {
      const { email, recaptcha } = payload;

      fetchAPI(`system/company/newsletter`, {
        method: 'post',
        parameters: {
          email,
          recaptcha
        }
      }).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      })
    });
  }), 

  unsubscribeFromNewsletter: thunk(async (actions, payload, { dispatch, getState, getStoreActions, injections }) => {
    return new Promise((resolve, reject) => {
      const { email, recaptcha } = payload;

      fetchAPI(`system/company/newsletter`, {
        method: 'delete',
        parameters: {
          email,
          recaptcha,
        }
      }).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      })
    });
  }), 
}
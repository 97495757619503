import { action, actionOn, thunk } from 'easy-peasy';
import { setStorage, getStorage, removeStorageItem } from 'utils/storage';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { getUserAttributes } from 'helpers/auth';
import { validateJwtResponse } from 'utils/security';
import { fetchAPI } from 'utils/net';
import { setAuthData } from 'utils/AuthStorage';

const AUTH_CONTEXT_KEY = '__auth__';

export default {
  idToken: null,
  accessToken: null,
  refreshToken: null,

  setAuthTokens: action((state, payload) => {
    const {
      idToken,
      accessToken,
      refreshToken,
    } = payload;

    state.idToken = idToken;
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;

    setAuthData({
      idToken,
      accessToken,
      refreshToken,
    });
  }),

  clearAuthTokens: action((state, payload) => {
    state.idToken = null;
    state.accessToken = null;
    state.refreshToken = null;
  }),

  checkAuthStorage: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      getStorage(AUTH_CONTEXT_KEY).then(data => {
        if (data) {
          actions.setAuthTokens(data);
          
          resolve(data);  
        }
        else {
          resolve(null);
        }
      }).catch(err => {
        resolve(null);
      })
    });
  }),

  validateNetTokens: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const token_expired_message = 'Please login again';

      Auth.currentSession().then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration();
        const refreshToken = session.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);

        // console.log(">>>> Auth.currentSession:", refreshToken);

        if (idTokenExpire < currentTimeSeconds) {
          // console.log(">>>>> Token Expired:", idTokenExpire, ", ", currentTimeSeconds);
          Auth.currentAuthenticatedUser().then(curUser => {
            // console.log(">>>>  Auth.currentAuthenticatedUser:", curUser)

            curUser.refreshSession(refreshToken, (err, data) => {
              if (err) {
                // console.log(">>>>> Error Refreshing Token:", err);
                actions.logout(token_expired_message).then(_ => {}).catch(_ => {});
              } else {
                // console.log(">>>>> Token Refreshed:", data);
                const idToken = data.idToken.jwtToken;
                const accessToken = data.accessToken.jwtToken;
                const refreshToken = data.refreshToken.token;

                setStorage(AUTH_CONTEXT_KEY, {idToken, accessToken, refreshToken})
                  .then(_ => {
                    actions.setAuthTokens({idToken, accessToken, refreshToken});
                  }).catch(err => {
                  
                  }).finally(_ => {
                    resolve({idToken, accessToken, refreshToken});
                  });
              }
            });
          }).catch(err => {
            if (err.toString() === 'The user is not authenticated') {
              actions.logout();
            }
          });
        } else {
          resolve({
            idToken: session.idToken.jwtToken,
            accessToken: session.accessToken.jwtToken,
            refreshToken
          });
        }
      })
      .catch(err => {
        actions.logout(token_expired_message).then(_ => {}).catch(_ => {});
      });
    });
  }),

  forgotPassword: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const {account, recaptcha} = payload;

      fetchAPI('auth/account/passwords/reset', {
        method: 'post',
        parameters: {
          recaptcha,
          account
        }
      }).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      });
    });
  }),

  confirmForgotPassword: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const {account, verification_code, password} = payload;

      fetchAPI('auth/account/passwords/confirm', {
        method: 'post',
        parameters: {
          account,
          verification_code,
          password
        }
      }).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      });
    });
  }),

  confirmSignUp: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const {account, code, password} = payload;

      fetchAPI(`auth/sign-ups/confirm`, {
        method: 'post',
        parameters: {
          signup_type: _.includes(account, '@') ? 'email' : 'mobile',
          account,
          code,
        }
      }).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      })
    });
  }),
  
  resendConfirmationCode: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const {account} = payload;

      Auth.resendSignUp(account).then(result => {
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }),

  signUp: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const {mobile, email, password, username, signup_type, name, account_type, recaptcha} = payload;
      
      fetchAPI(`auth/sign-ups`, {
        method: 'post',
        parameters: {
          signup_type,
          account: email || mobile,
          display_name: name,
          password,
          recaptcha
        }
      }).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      });
    });
  }),

  login: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      const {account, password}  = payload;

      fetchAPI(`auth`, {
        method: 'post',
        parameters: {
          account,
          password,
        }
      }).then(data => {
        const idToken = data.idToken.jwtToken;
        const accessToken = data.accessToken.jwtToken;
        const refreshToken = data.refreshToken.token;

        setStorage(AUTH_CONTEXT_KEY, {idToken, accessToken, refreshToken})
          .then(_ => {
            actions.setAuthTokens({idToken, accessToken, refreshToken});
          }).catch(err => {
          
          }).finally(_ => {
            resolve(data);
          });
      }).catch(err => {
        reject(err);
      });
    });  
  }),

  logout: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
    return new Promise((resolve, reject) => {
      Auth.signOut()
      .then(_ => {})
      .catch(_ => {})
      .finally(_ => {
        removeStorageItem(AUTH_CONTEXT_KEY).then(_ => {
          actions.clearAuthTokens();

          resolve(true);
        });
      });
    });  
  }),
}
function RecaptchaText(props) {

  return (
    <div className={`recaptcha-terms ${props.className}`}>
      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms"  target="_blank">Terms of Service</a> apply.
    </div>
  )
}


export default RecaptchaText;
import PageLayout from "naxos/components/Sections/Layout";
import Loader from "naxos/components/Sections/Loader";
import Header from "./Header";
import Footer from "naxos/components/Sections/Footer";
import ToTop from "naxos/components/Sections/ToTop";
import { Outlet } from "react-router-dom";


const Layout = ({}) => {
  return (
    <PageLayout 
      disableHeaderScroll={true}
    >
      <Header nav="home" />
      <Outlet />
      <Footer />
      <ToTop />
    </PageLayout>
  )
}

export default Layout;
import Storage from 'localforage';

const setStorage = (key, value) => {
  return new Promise((resolve, reject) => {
    const jsonValue = value === null ? null : (typeof value === 'String' ? value : JSON.stringify(value));

    Storage.setItem(key, jsonValue, (error) => {
      if (error) {
        reject(error);
      }
      else {
        resolve(jsonValue);
      }
    });
  });
}

const getStorage = (key) => {
  return new Promise((resolve, reject) => {    
    Storage.getItem(key, (error, value) => {
      if (error) {
        reject(error);
      }
      else {
        const jsonValue = value != null ? JSON.parse(value) : null;
        resolve(jsonValue);
      }
    });
  });
}

const removeStorageItem = (key) => {
  return new Promise((resolve, reject) => {    
    Storage.removeItem(key, (error) => {
      if (error) {
        resolve(true);
      }
      else {
        resolve(true);
      }
    });
  });
}

const clearAllStorage = (key) => {
  return new Promise((resolve, reject) => {    
    Storage.clear((error) => {
      if (error) {
        resolve(true);
      }
      else {
        resolve(true);
      }
    });
  });
}

export {
  Storage,
  clearAllStorage,
  setStorage,
  getStorage,
  removeStorageItem,
}
import { action, actionOn, thunk } from 'easy-peasy';
import { setStorage, getStorage, clearAllStorage } from 'utils/storage';
import { fetchAPI, memberFetchAPI } from 'utils/net';
import _ from 'lodash';
import moment from 'moment';
import { validateJwtResponse } from 'utils/security';

export default {
  subscriptions: [],

  updateFetchingSubscriptions: action((state, payload) => {
    state.is_fetching_subscriptions = payload;
  }),

  setSubscriptions: action((state, payload) => {
    state.subscriptions = payload;
  }),

  getSubscriptions: thunk(async (actions, payload, { dispatch, getState, getStoreActions, injections }) => {
    return new Promise((resolve, reject) => {
      const signal = payload?.signal;

      fetchAPI(`subscriptions/web`, {
        method: 'get',
        signal,
      }).then(payload => {
        const result = validateJwtResponse(payload);

        actions.setSubscriptions(result.plans);

        resolve(result.plans);
      }).catch(err => {
        reject(err);
      });
    });
  }),

  clearSubscriptions: thunk(async (actions, payload, { dispatch, getState, getStoreActions, injections }) => {
    actions.setSubscriptions([]);
  }),

  subscriptionCheckout: thunk(async (actions, payload, { dispatch, getState, getStoreActions, injections }) => {
    return new Promise((resolve, reject) => {
      const {product_id, price_id } = payload;

      memberFetchAPI(`subscriptions/web/checkout`, {
        method: 'post',
        parameters: {
          product_id,
          price_id,
        }
      }, {getStoreActions}).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      })
    });
  }),

  manageSubscription: thunk(async (actions, payload, { dispatch, getState, getStoreActions, injections }) => {
    return new Promise((resolve, reject) => {
      memberFetchAPI(`subscriptions/web/manage`, {
        method: 'post',
      }, {getStoreActions}).then(payload => {
        resolve(payload);
      }).catch(err => {
        reject(err);
      })
    });
  }),
}

import React, { useEffect } from 'react';

const Layout = (props) => {
  const handleScroll = () => {
    const toTop = document.querySelector('.to-top');
    const mainMenu = document.querySelector('.main-menu-area');
    const pos = window.scrollY;

    // Fixed menu
    if (mainMenu) {
        if (pos >= 100) {
            mainMenu.classList.add('fixed-menu', 'animate', 'slideInDown');
        } else {
            mainMenu.classList.remove('fixed-menu', 'animate', 'slideInDown');
        }
    }

    // Scroll to top button
    if (pos >= 500) {
        toTop.classList.add('fixed-totop');
    } else {
        toTop.classList.remove('fixed-totop');
    }
  }


	// Scroll
  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {      
      // Page loader
      const loader = document.querySelector('.page-loader');      

      if (loader) {
          setTimeout(() => {        
              loader.style.display = 'none';
          }, 100);
      }

      // Scroll event
      if (!!!props.disableHeaderScroll) {
        window.addEventListener('scroll', handleScroll);
      }
      else {
        window.removeEventListener('scroll', handleScroll);
      }
    }

    return () => {
      unmounted = true;

      window.removeEventListener('scroll', handleScroll);
    };
  }, [props]);


	return (
		<div>
			{props.children}
		</div>
	);
}

export default Layout;
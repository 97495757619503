import { JWT_PUB_KEY } from 'config/constants';
import { KEYUTIL, KJUR } from 'jsrsasign';
import jwt_decode from 'jwt-decode';

const validateJwtResponse = (token) => {
  const decoded_token = jwt_decode(token);
  const {token_expires, iat} = decoded_token;

  let publicKey = KEYUTIL.getKey(JWT_PUB_KEY);
  let isValid = KJUR.jws.JWS.verifyJWT(token, publicKey, {
    alg: ['RS256'], 
    verifyAt: iat,
  });

  console.log(">>>> validateJwtResponse:", isValid)
  console.log(">>>> decoded_token:", decoded_token) 

  if (isValid) {

  }
  else {

  }

  return decoded_token;
}

export {
  validateJwtResponse,
}
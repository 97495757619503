const IOS_STORE_LINK = process.env.REACT_APP_IOS_STORE_LINK;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//const API_BASE_URL = process.env.REACT_APP_API_HOST;
const JWT_PUB_KEY = process.env.REACT_APP_JWT_PUB_KEY;
const API_KEY = process.env.REACT_APP_API_KEY;
const REGION_SERVICES_REFRESH_MINS = 5;

const COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const PINPOINT_APP_ID = process.env.REACT_APP_PINPOINT_APP_ID;
const PINPOINT_REGION = process.env.REACT_APP_PINPOINT_REGION;
const IOT_REGION = process.env.REACT_APP_IOT_REGION;
const IOT_ENDPOINT_HOST = process.env.REACT_APP_IOT_ENDPOINT_HOST;

const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export {
  IOS_STORE_LINK,
  API_KEY,
  API_BASE_URL,
  JWT_PUB_KEY,
  REGION_SERVICES_REFRESH_MINS,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID,
  PINPOINT_APP_ID,
  PINPOINT_REGION,
  IOT_REGION,
  IOT_ENDPOINT_HOST,
  STRIPE_PK,
  RECAPTCHA_KEY,
}